@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Verdana, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul, li{
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body{

}

#root{
  min-width: 900px;
  max-width: 1200px;
  margin: 0px auto;
}

.authButton{
  background: 15px center / 20px 20px no-repeat url('img/google.png') ;

}

.ButtonSign{
  margin-top: -3px;
}

